/**
 * Theme for Virgin AU site
 **/
import type { Theme } from '@emotion/react';
import { buildTheme, globals } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types.d';
import { buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW, buildThemeVarsAPACLW } from 'dw-apac-law/theme';
import { montserratFont, gothamFont } from '../common/nextFont';

export const buildThemeVarsAPACVws = (vars: Pick<Theme, CommonThemeVars>) => {
  //primary
  const virCherryPie = '#2D054E'; //color2

  //fonts
  const montserrat = montserratFont.style.fontFamily;
  const gotham = gothamFont.style.fontFamily;
  //secondary

  const lwThemeVars = buildThemeVarsAPACLW(vars);
  return merge({}, lwThemeVars, {
    meta: {
      name: 'Virgin Redemption AU',
    },
    icons,
    assets: {
      headerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      headerLogoMobileImgUrl: `${globals.assetsPath}/next/images/brand-logo.svg`,
      footerLogoImgUrl: `${globals.assetsPath}/next/images/brand-logo-white.svg`,
    },
    colors: {
      //specific colour definitions
      footerPrimaryBg: virCherryPie,

      bgColors: {},
    },
    fonts: {
      familyPrimary: montserrat,
      familySecondary: lwThemeVars.fonts.roboto,
      familyTeritary: gotham,
    },
  });
};

export const buildThemeMixinsAPACVws = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  const buildThemeWidget = buildThemeMixinsAPACLW(vars);

  return merge({}, buildThemeWidget, {
    hxl: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize2};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize60};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h1: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize36};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight100};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize48};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight100};
      }
    `,
    h2: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize32};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize40};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h3: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize28};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight105};
      letter-spacing: ${vars.fonts.fontLetterSpacing4};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize36};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight105};
      }
    `,
    h4: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize24};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize30};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h5: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize22};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize26};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    h6: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight115};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize24};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight115};
      }
    `,
    h7: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize18};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize22};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    h8: `
      font-family: ${vars.fonts.familyPrimary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize18};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
    eyebrowRegular: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize14};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    eyebrowSmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing7};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    utility: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize10};
      font-weight: ${vars.fonts.weightSemiBold};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing9};
      text-transform: ${vars.fonts.caseUpper};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize12};
        line-height: ${vars.fonts.fontLineHeight100};
        letter-spacing: ${vars.fonts.fontLetterSpacing8};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    quote: `
      font-family: ${vars.fonts.familyTeritary};
      font-size: ${vars.fonts.fontSize20};
      font-weight: ${vars.fonts.weightLight};
      font-style: ${vars.fonts.styleItalic};
      line-height: ${vars.fonts.fontLineHeight110};
      letter-spacing: ${vars.fonts.fontLetterSpacing3};
      ${vars.breakpoints.sm} {
        font-size: ${vars.fonts.fontSize28};
      }
      span {
        line-height: ${vars.fonts.fontLineHeight110};
      }
    `,
    body1: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize16};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight140};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight140};
      }
    `,
    body2: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize14};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    bodySmall: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight130};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight130};
      }
    `,
    caption: `
      font-family: ${vars.fonts.familySecondary};
      font-size: ${vars.fonts.fontSize12};
      font-weight: ${vars.fonts.weightNormal};
      line-height: ${vars.fonts.fontLineHeight120};
      letter-spacing: ${vars.fonts.fontLetterSpacing5};
      span {
        line-height: ${vars.fonts.fontLineHeight120};
      }
    `,
  });
};

export const buildThemeWidgetStylesAPACVws = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesAPACLW(vars);

  return merge({}, buildThemeWidget, {
    GlobalHeader: {
      headerLogo: `
      img {
        max-width: 6.5em;
      }`,
    },
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      font-family: ${vars.fonts.familySecondary};
      p {
        font-family: ${vars.fonts.familySecondary};
      }
      @media print {
        display: none;
      }
      .footer-bottom-section, .social-connections  {
        background: ${vars.colors.footerPrimaryBg};
        box-shadow: 0 0 0 100vmax ${vars.colors.footerPrimaryBg};
        clip-path: inset(0 -100vmax);
        color: ${vars.colors.white};
        .wrapper {
          .copyrights {
            p {
              color: ${vars.colors.white};
              a {
                color: ${vars.colors.white};
              }
            }
          }
          .brand img.brand-footer-img {
            max-width: 15em!important;
            width: 201px!important;
          }
          .bedrinkaware-group {
            filter:invert(1);
            justify-content: center;
            ${vars.breakpoints.lg} {
              justify-content: flex-start;
            }
          }
        }
      }
      .social-connections {
        .wrapper {
          .social-connection-heading p {
            font-family: ${vars.fonts.familySecondary};
            letter-spacing: 2px;
            color: ${vars.colors.white};
          }
          a {
            color: ${vars.colors.white};
          }
        }
      }
      `,
      navItems: `
        ${buildThemeWidget.Footer.navItems}
        > div:first-of-type p{
          text-transform: uppercase;
          font-weight: bold;
        }
  `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACVws, buildThemeMixinsAPACVws, buildThemeWidgetStylesAPACVws);

export default createTheme;
