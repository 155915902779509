/**
 * Theme for Laithwaites AU site
 */
import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsLW, buildThemeVarsLW, buildThemeWidgetStylesLW } from 'dw-uk-law/theme';

export const buildThemeVarsAPACLW = (vars: Pick<Theme, CommonThemeVars>) => {
  const lwPink = '#E2004D';
  const lwPink500 = '#E2004D';
  const lwPinkOfferPill = '#CF004F';
  const lwOceanGreen = '#009F8E';
  const lwGrey700 = '#616161';
  const lwUnlimitedMemberPrice = '#009F8E';
  const lwCTAGreen = '#117B53';
  const lwUnlimitedMemberText = '#009F8E';
  const unlimitedPopupbackground = '#112B4B';
  const lwlightRed = '#FBE6F1';
  const lwJoinWhite = '#FFFFFF';
  const lwBodyBlack = '#222222';
  const lwGrey400 = '#BCBCBC';

  const colors = {
    primary1: lwPink,
    primary5: lwlightRed,
    primary6: lwOceanGreen,
    btnsWhiteBg: lwJoinWhite,
    textPrimaryBg: lwPink500,
    textSecondaryBg: lwGrey700,
    textUnlimitedPrice: lwUnlimitedMemberPrice,
    textUnlimitedBg: lwUnlimitedMemberText,
    unltPopupBackground: unlimitedPopupbackground,
    btnsPrimaryBg: lwCTAGreen,
    lwJoinWhite: lwJoinWhite,
    lwBodyBlack: lwBodyBlack,
    pinkOfferPillBg: lwPinkOfferPill,
    lwGrey400,
    savingsTextColor: lwPink500,
  };
  const fonts = {
    sizeH5: '18px',
    weightMediumPlus: '600',
  };

  const lwThemeVars = buildThemeVarsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Laithwaites AU',
    },
    icons,
    colors,
    fonts,
  });
};
export const buildThemeMixinsAPACLW = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  return buildThemeMixinsLW(vars);
};
export const buildThemeWidgetStylesAPACLW = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesLW(vars);
  return merge({}, buildThemeWidget, {
    Search: {
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}
      .ais-Highlight-nonHighlighted, .country, .web-description, .review-count,.hit-content {
        color: ${vars.colors.grey50};
      }
      .ais-Hits-list {
        .ais-Hits-item {
          .stock {
            background-color: ${vars.colors.pinkOfferPillBg}
          }
        }
      }
      &.list-view {
        .ais-Hits-list {
          grid-gap: 0px;
          .ais-Hits-item {
            gap: 0;
            &:first-of-type {
              border-top: 1px solid #C4C7C5;
              ${vars.breakpoints.xs} {
                margin-top: 16px;
              }
              @media (min-width: 767px) {
                margin-top: inherit;
              }
              ${vars.breakpoints.sm} {
                margin-top: inherit;
              }
            }
            ${vars.breakpoints.xs} {
              grid-template-columns: none;
              border-radius: 8px;
              border: 1px solid #C4C7C5;
              margin-bottom: 16px;
            }
            @media (min-width: 767px) {
              grid-template-columns: 350px 366px;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid #DBDBDB;
              margin: 0;
              padding: 16px 0 !important;
            }
            ${vars.breakpoints.sm} {
              grid-template-columns: 350px 366px;
              border: none;
              border-radius: 0;
              border-bottom: 1px solid #DBDBDB;
              margin: 0;
              padding: 16px 16px 16px 0 !important;
            }
            ${vars.breakpoints.md} {
              grid-template-columns: 350px 354px;
            }
            ${vars.breakpoints.lg} {
              grid-template-columns: 293px auto;
            }
            ${vars.breakpoints.xl} {
              grid-template-columns: 430px auto;
            }
            ${vars.breakpoints.xxl} {
              grid-template-columns: 618px auto;
            }

            .unlimited-icon {
              i{
                font-size: ${vars.fonts.sizeH7};
              }
            }
            .image-bg {
              padding: 0;
              .image-container {
                margin: 4px;
                .offer {
                  .stock {
                    bottom: 10px;
                    background: ${vars.colors.pinkOfferPillBg};
                  }
                  svg {
                    ${vars.breakpoints.xs} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.sm} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.md} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.lg} {
                      height: 38px;
                      width: 44px;
                      left: 10px;
                      top: 10px;
                    }
                    ${vars.breakpoints.xl} {
                      height: 64px;
                      width: 55px;
                      left: 16px;
                      top: 16px;
                    }
                    ${vars.breakpoints.xxl} {
                      height: 64px;
                      width: 55px;
                      left: 16px;
                      top: 16px;
                    }
                  }
                }
              }
            }

            .hit-content {
              ${vars.breakpoints.xs} {
                padding: 12px 16px;
              }
              @media (min-width: 767px) {
                padding: 0 0px 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.sm} {
                padding: 0 0px 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.md} {
                padding: 0 0 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.lg} {
                padding: 0px 24px 16px 24px;
                text-align: left;
              }
              ${vars.breakpoints.xl} {
                padding: 32px 0px 32px 72px;
                text-align: left;
              }
              ${vars.breakpoints.xxl} {
                padding: 32px 0px 32px 72px;
                text-align: left;
              }
              margin-bottom: 0;
              .title {
                font-family: ${vars.fonts.familyTertiary};
                font-weight: ${vars.fonts.weightMediumPlus};
                line-height: 31px;
                color: ${vars.colors.lwBodyBlack};
                margin-bottom: 16px;
                ${vars.breakpoints.xs} {
                  font-size: ${vars.fonts.sizeH5};
                }
                @media (min-width: 767px) {
                  font-size: ${vars.fonts.sizeH5};
                }
                ${vars.breakpoints.lg} {
                  font-size: ${vars.fonts.sizeH5};
                }
                ${vars.breakpoints.xl} {
                  font-size: ${vars.fonts.sizeH9};
                }
              }
              .inline-container {
                font-family: ${vars.fonts.familySecondary};
                ${vars.breakpoints.xs} {
                  margin: 12px 0;
                  font-size: ${vars.fonts.sizeH5};
                }
                @media (min-width: 767px) {
                  justify-content: start;
                  margin: 16px 0;
                }
                ${vars.breakpoints.sm} {
                  justify-content: start;
                  margin: 16px 0;
                }
                .country-rating {
                  margin: 0;
                  .country {}
                  .rating {
                    ${vars.breakpoints.xs} {
                      margin-top: 8px;
                    }
                    @media (min-width: 767px) {
                      justify-content: start;
                      margin-top: 16px;
                    }
                    ${vars.breakpoints.sm} {
                      justify-content: start;
                      margin-top: 16px;
                    }
                  }
                }
              }
              .web-description {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                
                font-family: ${vars.fonts.familySecondary};
                font-weight: ${vars.fonts.weightNormal};
                font-size: ${vars.fonts.sizeLarge};
                line-height: 24px;
                ${vars.breakpoints.xs} {
                  margin: 12px 0;
                }
                @media (min-width: 767px) {
                  margin: 16px 0;
                }
                ${vars.breakpoints.sm} {
                  margin: 16px 0;
                }
              }
              .happy-to-wait-container {
                min-height: 46px;
                margin: 10px 0;
                background-color: #B3CFE5;
                padding: 12px 16px;
                text-align: center;
                margin-bottom: 15px;
                width: 100%;
                border-radius: 4px;
                .date-expected {
                  font-weight: ${vars.fonts.weightBold};
                }
              }
              .pricing-panel-container {
                .top-price-section {
                  padding-top: 0;
                  margin-bottom: 8px;
                  @media (max-width: 1024px) {
                    margin-bottom: 4px;
                  }
                  .price-section {
                    .case-sku-savings-sales-price {
                      font-family: ${vars.fonts.familyTertiary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      line-height: 26.5px;
                      color: ${vars.colors.textPrimaryBg};
                      ${vars.breakpoints.xs} {
                        font-size: ${vars.fonts.sizeH7};
                      }
                      @media (min-width: 767px) {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.lg} {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xl} {
                        font-size: ${vars.fonts.sizeH8};
                      }
                    }
                    .case-sku-sales-price {
                      ${vars.breakpoints.xs} {
                        font-size: ${vars.fonts.sizeH7};
                      }
                      @media (min-width: 767px) {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xl} {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xxl} {
                        font-size: ${vars.fonts.sizeH8};
                      }
                    }
                    .strike-case-sku-pricing-panel {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeLarge};
                      line-height: 22.5px;
                      color: ${vars.colors.textSecondaryBg};
                      align-self: center;
                      margin-left: 8px;
                    }
                  }
                  .save-pill {
                    padding: 3px 5px;
                    font-family: ${vars.fonts.familySecondary};
                    font-weight: ${vars.fonts.weightBold};
                    font-size: ${vars.fonts.sizeSmall};
                    line-height: 18px;
                    color: ${vars.colors.textPrimaryBg};
                    border-radius: 30px;
                  }
                }
                .case-details-savings {
                  padding-top: 0;
                  margin-bottom: 16px;
                  text-align: left;
                }
                .case-details-isUnlimited {
                  padding-top: 0;
                  margin-bottom: 16px;
                  text-align: left;
                }
                .case-details {
                  padding-top: 0;
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightNormal};
                  line-height: 22.5px;
                  color: ${vars.colors.textPrimaryBg};
                  text-align: left; 
                  ${vars.breakpoints.xs} {
                    margin-bottom: 12px;
                  }
                  @media (min-width: 767px) {
                    margin-bottom: 16px;
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.sm} {
                    margin-bottom: 16px;
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.md} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  ${vars.breakpoints.lg} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  ${vars.breakpoints.xl} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                  ${vars.breakpoints.xxl} {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                }
                .member-price-section-case-sku {
                  .member-price-section-vpp {
                    ${vars.breakpoints.xs} {
                      margin: 12px 0;
                      display: block;
                      text-align: left;
                    }
                    @media (min-width: 767px) {
                      margin: 16px 0;
                      display: flex;
                    }
                    ${vars.breakpoints.sm} {
                      margin: 16px 0;
                      display: flex;
                    }
                  }
                  .member-price-section {
                    ${vars.breakpoints.xs} {
                      margin: 12px 0;
                      display: block;
                      text-align: left;
                    }
                    @media (min-width: 767px) {
                      margin: 16px 0;
                      display: flex;
                    }
                    ${vars.breakpoints.sm} {
                      margin: 16px 0;
                      display: flex;
                    }
                    .member-price {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 16px;
                      color: ${vars.colors.textUnlimitedPrice};
                    }
                    .learn-more-isUnlimited {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 17px;
                      color: ${vars.colors.lwBodyBlack};
                    }
                  }
                }
                .straight-sku-container {
                  .vpp-price {
                    margin-top: 0;
                    text-align: left;
                    ${vars.breakpoints.xs} {
                      margin-bottom: 12px;
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    @media (min-width: 767px) {
                      margin-bottom: 16px;
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.sm} {
                      margin-bottom: 16px;
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    .price {
                      ${vars.breakpoints.xs} {
                        font-size: ${vars.fonts.sizeH7};
                      }
                      @media (min-width: 767px) {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.lg} {
                        font-size: ${vars.fonts.sizeH5};
                      }
                      ${vars.breakpoints.xl} {
                        font-size: ${vars.fonts.sizeH8};
                      }
                    }
                  }
                  .top-price-section {
                    padding-top: 0;
                    ${vars.breakpoints.xs} {
                      margin-bottom: 12px;
                    }
                    @media (min-width: 767px) {
                      margin-bottom: 16px;
                    }
                    ${vars.breakpoints.sm} {
                      margin-bottom: 16px;
                    }
                    .price-section-sku {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeLarge};
                      line-height: 22.5px;
                      color: ${vars.colors.lwBodyBlack};
                      .price {
                        font-family: ${vars.fonts.familyTertiary};
                        font-weight: ${vars.fonts.weightMediumPlus};
                        line-height: 26.5px;
                        color: ${vars.colors.lwBodyBlack};
                        ${vars.breakpoints.xs} {
                          font-size: ${vars.fonts.sizeH7};
                        }
                        @media (min-width: 767px) {
                          font-size: ${vars.fonts.sizeH5};
                        }
                        ${vars.breakpoints.lg} {
                          font-size: ${vars.fonts.sizeH5};
                        }
                        ${vars.breakpoints.xl} {
                          font-size: ${vars.fonts.sizeH8};
                        }
                      }
                    }
                  }
                }
                .addToCart-QuantitySelector {
                  .add-to-cart-section {
                    padding-top: 0;
                  }
                  .add-to-cart-btn-container {
                    .cart-button {
                      margin: 0;
                      button {
                        padding: 12px 24px;
                        font-family: ${vars.fonts.familySecondary};
                        font-weight: ${vars.fonts.weightMediumPlus};
                        font-size: ${vars.fonts.sizeDefault};
                        line-height: 16px;
                        color: ${vars.colors.lwJoinWhite};
                      }
                    }
                  }
                }
                .vpp-btn {
                  margin-top: 0;
                  .separation-line {
                    margin: 20px 0;
                  }
                  .cart-button {
                    margin: 8px 0 0 0;
                    button {
                      padding: 12px 24px;
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 16px;
                      color: ${vars.colors.lwJoinWhite};
                      ${vars.breakpoints.xs} {
                        padding: 12px;
                      }
                      @media (min-width: 767px) {
                        padding: 12px 24px;
                      }
                      ${vars.breakpoints.sm} {
                        padding: 12px 24px;
                      }
                    }
                  }
                  .member-price-section-vpp {
                    ${vars.breakpoints.xs} {
                      display: block;
                      text-align: left;
                      margin-top: 12px;
                    }
                    @media (min-width: 767px) {
                      display: flex;
                      margin-top: 8px;
                    }
                    ${vars.breakpoints.sm} {
                      display: flex;
                      margin-top: 8px;
                    }
                  }
                  .member-price-section {
                    ${vars.breakpoints.xs} {
                      display: block;
                      text-align: left;
                      margin-top: 12px;
                    }
                    @media (min-width: 767px) {
                      display: flex;
                      margin-top: 8px;
                    }
                    ${vars.breakpoints.sm} {
                      display: flex;
                      margin-top: 8px;
                    }
                    .member-price {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 16px;
                      color: ${vars.colors.textUnlimitedPrice};
                    }
                    .learn-more-isUnlimited {
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 17px;
                      color: ${vars.colors.lwBodyBlack};
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.grid-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            .image-bg {
              height: 244px;
              display: flex;
              flex-direction: column;
              padding: 0;

              .image-container {
                padding: 12px 16px;
                position: relative;
                height: 100%;

                .image {
                  margin: 0;
                  img {
                    position: relative !important;
                    height: 220px !important;
                  }
                }

                .offer {
                  display: flex;
                  flex-direction: column;
                  position: absolute;
                  left: 16px;
                  top: 12px;
                  bottom: 12px;
                  svg {
                    top: 0;
                    left: 0
                  }

                  .stock {
                    z-index: 1;
                    left: -6px;
                    bottom: 0;
                    width: 77px;
                    ${vars.breakpoints.xxl} {
                      left: 0;
                    }
                    ${vars.breakpoints.xl} {
                      left: 0;
                    }
                  }
                }

                .quick-links-container {
                  bottom: 15px;
                }
              }
            }

            .hit-content {
              padding: 24px 16px 0;
              justify-content: start;

              .title {
                min-height: 50px;
                letter-spacing: -0.22px;
                ${vars.breakpoints.md} {
                  min-height: 62px;
                  font-size: 15px;
                }
                ${vars.breakpoints.lg} {
                  min-height: 62px;
                  font-size: ${vars.fonts.sizeH4};
                }
                ${vars.breakpoints.xl} {
                  min-height: 62px;
                }
                ${vars.breakpoints.xxl} {
                  min-height: 62px;
                  font-size: ${vars.fonts.sizeH8};
                }
              }

              .case {
                position: unset;
                width: 100%;
                transform: none;
                padding: 2px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                color: ${vars.colors.lwBodyBlack};
                ${vars.breakpoints.md} {
                  display: -webkit-box !important;
                  margin: 0 !important;
                }
                ${vars.breakpoints.lg} {
                  display: -webkit-box !important;
                  margin: 0 !important;
                }
                ${vars.breakpoints.xl} {
                  display: -webkit-box;
                  margin: 14.5px 0 16px !important;
                }
                ${vars.breakpoints.xxl} {
                  margin: 14.5px 0 16px !important;
                  display: -webkit-box;
                }
              }

              .web-description {
                height: auto;
                min-height: auto;
                margin: 14.5px 0 16px;
                padding: 0;
                font-size: ${vars.fonts.sizeH7};
                color: ${vars.colors.lwBodyBlack};
                ${vars.breakpoints.md} {
                  font-size: ${vars.fonts.sizeDefault};
                }
                ${vars.breakpoints.lg} {
                  font-size: ${vars.fonts.sizeDefault};
                  display: none;
                }
                ${vars.breakpoints.xl} {
                  font-size: ${vars.fonts.sizeDefault};
                  display: -webkit-box;
                  height: 39px;
                }
                ${vars.breakpoints.xxl} {
                  font-size: ${vars.fonts.sizeH4};
                  height: 44px;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              .inline-container {
                display: block;
                font-style: normal;

                .country-rating {
                  display: block;
                  margin: 0;
                  ${vars.breakpoints.md} {
                    margin-top: 0px;
                  }
                  ${vars.breakpoints.lg} {
                    margin-top: 0px;
                  }
                  ${vars.breakpoints.xl} {
                    margin-top: 8px;
                  }
                  ${vars.breakpoints.xxl} {
                    margin-top: 8px;
                  }

                  .country {
                    margin-bottom: 8px;
                    color: ${vars.colors.lwBodyBlack};
                    @media (max-width: 1024px) {
                      display: flex;
                      flex-direction: column;
                      row-gap: 5px;
                    }
                    ${vars.breakpoints.lg} {
                      margin-bottom: 2px;
                    }

                    .country-container {
                      img {
                        width: auto;
                        margin: 0 !important;
                      }
                    }

                    .grapeVariety-container {
                      ${vars.breakpoints.md} {
                        margin-left: 0;
                      }
                      ${vars.breakpoints.lg} {
                        margin-left: 12px;
                      }
                      ${vars.breakpoints.xl} {
                        margin-left: 12px;
                      }
                      ${vars.breakpoints.xxl} {
                        margin-left: 12px;
                      }
                    }
                  }

                  .rating {
                    margin-top: 0;
                    .review-count {
                      color: ${vars.colors.lwBodyBlack};
                      text-decoration: none;
                      &:hover {
                        text-decoration: none;
                      }
                    }

                    a {
                      cursor: pointer;
                      text-decoration: none;
                      &:hover {
                        text-decoration: none;
                      }
                    }
                  }
                }


                .reserveTodayContainer {
                  margin: 0 auto!important;
                  width: 100%;
                  > div {
                    padding: 8px;
                    border-radius: 4px;
                    ${vars.breakpoints.md} {
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.xl} {
                      margin: 18.5px 0 24px;
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.xxl} {
                      margin: 18.5px 0 28px;
                      font-size: ${vars.fonts.sizeH7};
                    }
                  }

                  .reserve-text {
                    font-weight: 400;
                  }
                  a {
                    text-decoration: underline;
                  }
                }
              }
            }

            .addToCart-container { 
              .price-section {
                margin-bottom: 10px;
                text-align: center;
                line-height: 26px;
                .vpp-price {
                  text-align: left;
                }
                .sku-price {
                  font-family: ${vars.fonts.familyTertiary};
                  font-size: ${vars.fonts.sizeH5};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.grey50};
                  .presell-price {
                    font-family: ${vars.fonts.familySecondary};
                    font-weight: ${vars.fonts.weightBold};
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }
                  .presell-price + span {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }

                  .original-price-case {
                    font-size: ${vars.fonts.sizeLarge};
                    &.line-through {
                      text-decoration: line-through;
                      color: ${vars.colors.textSecondaryBg};
                      font-family: ${vars.fonts.familySecondary};
                      font-weight: ${vars.fonts.weightNormal};
                      padding-left:5px;
                    }
                  }

                  .original-price-bottle {
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                    .pricing-label {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                  }

                  .sales-price {
                    color: ${vars.colors.textPrimaryBg};
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xl} {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeH5};
                    }
                  }

                  .pricing-label {
                    font-size: ${vars.fonts.sizeLarge};
                    font-weight: ${vars.fonts.weightNormal};
                    font-family: ${vars.fonts.familySecondary};
                  }
                  .mix-price-label {
                    font-size: ${vars.fonts.sizeLarge};
                  }
                }
                .vpp-price-section {
                    color: ${vars.colors.textPrimaryBg};
                    ${vars.breakpoints.md} { 
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.lg} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xl} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xxl} { 
                      font-size: ${vars.fonts.sizeDefault};
                    }

                    .sales-price {
                      color: ${vars.colors.textPrimaryBg};
                      font-size: ${vars.fonts.sizeDefault};
                      font-weight: ${vars.fonts.weightMediumPlus};
                      @media (max-width: 1024px) {
                        font-size: 13px;
                      }
                    }
                  }

                  .vpp-price-label {
                    font-size: ${vars.fonts.sizeDefault};
                    font-weight: ${vars.fonts.weightNormal}!important;
                  }
                .member-price-section-case {
                  margin-bottom: 10px;
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.textUnlimitedPrice};
                  font-size: ${vars.fonts.sizeDefault};

                  .member-price-link , .member-price-link-mixed-case {
                    color: ${vars.colors.textUnlimitedPrice}!important;
                    font-size: ${vars.fonts.sizeDefault};
                    text-decoration: none;
                  }
                  .member-price-link:hover {
                    text-decoration: none!important;
                  }
                  .member-price-link-mixed-case {
                    text-decoration: underline;
                  }

                  .unlimited-icon {
                    padding-right: 7px;
                    font-size: 20px;
                    cursor: pointer;
                  }
                  .pricing-label {
                    font-weight: ${vars.fonts.weightNormal};
                  }
                  .member-price-label {
                    display: block;
                    ${vars.breakpoints.xxl} { 
                      display:inline;
                    }
                  }
                }

                .member-price-section-bottle {
                  margin-bottom: 10px;
                  
                  font-size: ${vars.fonts.sizeSmall};
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightBold};
                  color: ${vars.colors.textUnlimitedPrice}!important;
                  ${vars.breakpoints.lg} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.xl} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  ${vars.breakpoints.xxl} {
                    font-size: ${vars.fonts.sizeDefault};
                  }
                  .unlimited-icon {
                    padding-right: 7px;
                    font-size: 20px;
                    cursor: pointer;
                  }
                  .member-price-content{
                    color: ${vars.colors.textUnlimitedPrice}!important;
                  }
                  .pricing-label {
                    font-weight: ${vars.fonts.weightNormal};
                  }
                  .member-price-label {
                    ${vars.breakpoints.md} {
                      font-size: ${vars.fonts.sizeSmall};
                    }
                    ${vars.breakpoints.lg} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xl} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xxl} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                  }
                }
              }

              .add-to-cart-btn-container {
                margin: 0 auto;
                ${vars.breakpoints.md} {
                  width: 90%;
                }
                ${vars.breakpoints.lg} {
                  width: 83.5%;
                }
                ${vars.breakpoints.xl} {
                  width: 71%;
                }
                ${vars.breakpoints.xxl} {
                  width: 100%;
                }

                .tooltip-btn {
                  display: inline-block;
                  height: auto;
                  font-size: ${vars.fonts.sizeDefault};
                  border-radius: 5px;
                  line-height: 26px;
                  padding: 12px 24px;
                  border-width: 0;
                  border-style: solid;
                  background-color: ${vars.colors.btnsPrimaryBg};
                  color: ${vars.colors.lwJoinWhite};
                  border-width: 2px;
                  border-color: transparent;
                }
                .top-price-section {
                  padding: 0;
                  .price-section {
                    margin: 0;
                    .pricing-panel-container {
                      .straight-sku-container {
                        gap: 4px;
                        .vpp-price {
                          margin-top: 10px;
                        }
                        .top-price-section {
                          margin-top: 10px;
                          .price-section-sku {
                            font-size: ${vars.fonts.sizeLarge};
                          }
                        }
                      }
                      .addToCart-QuantitySelector {
                        margin-bottom: 24px;
                        .add-to-cart-section {
                          margin-top: 20px;
                          padding-top: 0;
                          .add-to-cart-btn-container {
                            .cart-button {
                              button {
                                font-weight: ${vars.fonts.weightNormal} !important;
                              }
                            }
                            .member-price-section {
                              margin-top: 10px;
                            }
                          }
                        }
                      }
                      .vpp-btn {
                        margin: 25px 0 24px 0;
                        .member-price-section {
                          margin-top: 10px;
                        }
                      }
                    }
                  }
                }
                .view-wine-link {
                  font-size: ${vars.fonts.sizeLarge};
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
      `,
    },
    Product: {
      wrapper: `  
      ${buildThemeWidget.Product.wrapper}

      .pill {
        &.stock {
          background-color: ${vars.colors.pinkOfferPillBg}!important;
          border-radius: 30px;
        }

      } 
      .about-the-wine-container{
        .auc-Recommend{
          .product-item-container{
            .product-price{
              color:${vars.colors.lwBodyBlack};
            }
          }
        }
      }
      .case-content-main-container{
      .print-share-container{
      display:none;
      }
      .award-main-container{
       display:flex;
       align-items: center;
       justify-content:center;
       ${vars.breakpoints.sm}{
       align-items: start;
       justify-content:start;
       }
      }
        .wineStyle-country-au{
        @media(max-width :767px){
        flex-direction:column;
        align-items: center;
        justify-content:center;
        list-style-type: none;
        }
          width:100%
          height:40px;
          list-style-type: none; 
          display: flex; 
          align-items: center;
          justify-content:start;
          padding: 10px 10px 10px 0px; 
          margin: 10px 10px 10px 0px; 
          a{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          }
          .wine-glass{
           margin-right:30px;
           padding:5px;
           @media(max-width :767px){
             padding:0px;
             margin:0px;
           }
         
          }
          .countryFlag{
             @media(max-width :767px){
             padding:5px;
           }
         }
          .countryName{
          padding:5px;
          }
        }
      }
      .product-layout{
        .layout-name{
          .sub-title{
            font-weight: 600;
            font-size: ${vars.fonts.sizeH8};
            line-height: 26.4px;
            font-family: ${vars.fonts.familyTertiary};
            padding-top: 0;
            margin: 16px 0;
            ${vars.breakpoints.sm} {
              padding-top: 10px;
              margin: 18px 0;
            }
          }
            .main-title{
              @media(max-width : 767px){
                font-size:30px;
                line-height: 31.5px;
              }
            }
        }
        .layout-details{
          .description-container{
            font-weight: ${vars.fonts.weightSmall};
            font-size: ${vars.fonts.sizeLarge};
            line-height: 22.4px;
            font-family: ${vars.fonts.familySecondary};
          }
          .wineStyle-country-au{
          @media(max-width :767px){
            flex-direction:column;
            align-items:start ;
            justify-content:center;
            list-style-type: none;
           }
          width:100%
          height:40px
          gap:16px;
          list-style-type: none; 
          display: flex; 
          align-items: center;
          justify-content:start;
          padding:0px; 
          li{
          margin:5px 0px 5px 0px;
          }
          a{
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding:10px
          @media(max-width :767px){
          padding:0px;
         }
          
          }
          .wine-glass{
           margin-right:30px;
           padding:5px;
         
          }
           .countryName{
            padding-left:5px;
            
         }
          .countryFlag{
            padding:0px;
            height:24px;
         }
        }
        .happy-to-wait-container {
          background-color: #B3CFE5;
          padding: 8px;
          text-align: center;
          margin-bottom: 15px;
          width: 100%;
          border-radius: 4px;
          @media (min-width: 1025px) {
            padding: 5px;
            margin-bottom: 5px;
          }
          ${vars.breakpoints.lg} {
            padding: 4px 8px;
            margin-bottom: 10px;
          }
          ${vars.breakpoints.xl} {
            padding: 8px;
            margin-top: 20px;
            margin-bottom: 15px;
          }
          ${vars.breakpoints.xxl} {
            margin-top: 0px;
            padding: 15px;
          }
          .date-expected {
            font-weight: ${vars.fonts.weightBold};
            margin-bottom: 5px;
          }
        }
        .money-back-guarantee {
          flex-direction: column;
          gap: 10px;
          &.apac {
              justify-content: space-evenly;
              margin-left: -15px;
              margin-right: -15px;
              ${vars.breakpoints.sm} {
                margin-left: 0px;
                margin-right: 0px;
              }
              .inner-container {
                display: flex;
                align-items: center;
                .icon {
                  margin-right: 10px;
                  i {
                    color: #112B4B;
                  }
                }
                .content {
                  .descp {
                    padding-bottom: 0px;
                    color: #112B4B;
                  }
                }
              }
          }
          ${vars.breakpoints.md} {
            flex-direction: row;
            gap: 0px;
          }
        }
      }
      `,
      orderform: ` 
      ${buildThemeWidget.Product.orderform}

      // ************** PRICING PANEL MIXED CASE CSS ************** 
      .top-price-section{
        align-items: flex-start;
        order: 2;
        .price-section.presell{
          font-size: 14px;
          display: block; 
          .sale-price{
            margin-top: 0px;
            .price{
              font-size: 22px;
              font-weight: ${vars.fonts.weightMediumPlus};
            }
          }
          .case-details {
            padding-top: 0px;
            color: ${vars.colors.black}!important;
            font-size: 14px!important;
          }
        }
        .price-section{
          display: flex;
          align-items: flex-start;
          .case-sku-savings-sales-price, .case-sku-sales-price, .case-sku-savings-sales-price-isUnlimited {
            font-weight: ${vars.fonts.weightMediumPlus};
            font-size: ${vars.fonts.sizeH8};
            font-family: ${vars.fonts.noto};
          }
          .case-sku-savings-sales-price {
            color: ${vars.colors.textPrimaryBg};
          }
          .case-sku-savings-sales-price-isUnlimited {
            color: ${vars.colors.textUnlimitedPrice};
          }
          .strike-case-sku-pricing-panel{
            color: ${vars.colors.textSecondaryBg};
            font-size: ${vars.fonts.sizeLarge};
            margin-left: 5px;
          }
        }
        .save-pill {
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeSmall};
          color: ${vars.colors.textPrimaryBg};
          background-color: ${vars.colors.primary5};
          padding: 0 8px;
          border-radius: 30px;
        }
      }
      .case-details-savings, .case-details, .case-details-isUnlimited{
        color: ${vars.colors.textPrimaryBg};
        font-size: ${vars.fonts.sizeLarge};
        padding-top: 10px;
      }
      .case-details-isUnlimited {
        color: ${vars.colors.textUnlimitedPrice};
      }
      .case-details{
        color: ${vars.colors.black};
      }
      // ************** PRICING PANEL MIXED CASE CSS ENDS ************** 
      
      // ************** COMMON CSS FOR PRICING PANEL ************** 

      .member-price-section, .member-price-section-vpp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .member-price {
          font-weight: ${vars.fonts.weightMediumPlus};
          color: ${vars.colors.textUnlimitedBg}
        }
      }
      .member-price-section {
        margin-top: 20px;
      }
      // ************** COMMON CSS FOR PRICING PANEL ENDS **************

      // ************** PRICING PANEL STRAIGHT CASE CSS ************** 
      .vpp-btn {
        margin-top: 25px;
        margin-bottom: 0px;
        .cart-button {
          margin: 10px 0px;
        }
      }
      .addToCart-QuantitySelector{
        display: flex;
        ${vars.breakpoints.xs} {
          margin-bottom: ${vars.space.md};
        }
        ${vars.breakpoints.sm} {
          margin-bottom: 0px;
        }
        
        .add-to-cart-btn-container{
          flex-grow: 1;
        }
      }
      .add-to-cart-section{
        flex-grow: 1;
        .customProdQantityField {
          & div:first-of-type {
            margin-right: 0px;
          }
        }
      }
     
        .customProdQantityField {
          
          .input-group-prepend .btn-minus, .input-group-append .btn-plus{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid ${vars.colors.textSecondayBg};
            background-color:${vars.colors.white};
            color: ${vars.colors.grey50};
          }
          .quantity{
            color: ${vars.colors.grey50};            
          }
          
        }

        .straight-sku-container{
          display:flex;
          flex-direction: column;
          .vpp-price {
            color: ${vars.colors.primary1};
          }
        }

      .top-price-section, .vpp-price, .vpp-price-isUnlimited-member {
        .price-section-sku, .first-price-head {
          display: inline;
          .price {
            font-family: ${vars.fonts.familyTertiary};
            font-weight: ${vars.fonts.weightMediumPlus};
            font-size: ${vars.fonts.sizeH8};
            line-height: 26.4px;
          }
        }
      }
      .vpp-price, .vpp-price-isUnlimited-member {
        &.first-price-head{ 
          .price {
            font-size: ${vars.fonts.sizeH8};
            font-family: ${vars.fonts.noto};
            font-weight: ${vars.fonts.weightMediumPlus};
          }
        }
      }
      .vpp-price-isUnlimited-member {
        color: ${vars.colors.textUnlimitedBg};
        order: 1;
        text-align: left;
        ${vars.breakpoints.xs} {
          margin-bottom: 12px;
        }
        @media (min-width: 767px) {
          margin-bottom: 16px;
        }
        ${vars.breakpoints.sm} {
          margin-bottom: 16px;
        }
      }
      // ************** PRICING PANEL STRAIGHT CASE CSS ************** 
      `,
      learnMoreWrapper: `
        section {
          flex: none;
        }
        .mantine-Modal-body {
          padding: 25px 20px 20px 20px;
          background: #F8F5EF;
          ${vars.breakpoints.xs} {
            width: 100%;
          }
          @media (min-width: 767px) {
            width: 408px;
          }
          ${vars.breakpoints.sm} {
            width: 408px;
          }

          .learnMore-header {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            position: relative;

            .unlimited-img {
              max-width: 100%;
              height: auto;

              i {
                color: ${vars.colors.textUnlimitedBg};
              }
            }

            .learnMore-closeBtn {
              position: absolute;
              top: 0;
              right: 0;

              i {
                font-size: ${vars.fonts.sizeH3};
              }
            }
          }

          .modalTitle {
            color: ${vars.colors.lwBodyBlack};
            font-family:${vars.fonts.familyPrimary};
            font-weight: ${vars.fonts.weightMedium};
            
            text-align: center;
            margin: 12px 0;
            padding: 0;
            ${vars.breakpoints.xs} {
              font-size: 25px;
              line-height: 29px;
            }
            @media (min-width: 767px) {
              font-size: ${vars.fonts.sizeH10};
              line-height: 33px;
            }
            ${vars.breakpoints.sm} {
              font-size: ${vars.fonts.sizeH10};
              line-height: 33px;
            }
          }

          .modalTitle2 {
            color: ${vars.colors.lwBodyBlack};
            font-family:${vars.fonts.familyTertiary};
            text-align: center;
            text-transform: none;
            letter-spacing: 0;
            margin-bottom: 12px;
            ${vars.breakpoints.xs} {
              font-weight: ${vars.fonts.weightBold};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 22px;
            }
            @media (min-width: 767px) {
              font-weight: ${vars.fonts.weightMediumPlus};
              font-size: ${vars.fonts.sizeLarger};
              line-height: 25px;
            }
            ${vars.breakpoints.sm} {
              font-weight: ${vars.fonts.weightMediumPlus};
              font-size: ${vars.fonts.sizeLarger};
              line-height: 25px;
            }
          }

          .divider {
            margin: 12px 0;
            border-top: 1px solid #222222;
          }

          .description {
            color: ${vars.colors.lwBodyBlack};
            font-family: ${vars.fonts.familySecondary};
            font-weight: ${vars.fonts.weightNormal};
            text-align: left;
            margin: 12px 0;
            padding: 0;

            ${vars.breakpoints.xs} {
              font-size: ${vars.fonts.sizeSmall};
              line-height: 15.5px;
            }
            @media (min-width: 767px) {
              font-size: ${vars.fonts.sizeDefault};
              line-height: 18px;
            }
            ${vars.breakpoints.sm} {
              font-size: ${vars.fonts.sizeDefault};
              line-height: 18px;
            }
          }

          .mantine-List-root {
            .incentives {
              color: ${vars.colors.lwBodyBlack};
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeDefault};
              margin: 12px 0;
              padding: 0;

                .mantine-List-itemWrapper {
                  span:first-of-type {
                    ${vars.breakpoints.xs} {
                      margin-right: 12px;
                    }
                    @media (min-width: 767px) {
                      margin-right: 16px;
                    }
                    ${vars.breakpoints.sm} {
                      margin-right: 16px;
                    }

                    div {
                      color: ${vars.colors.lwBodyBlack};
                      background: transparent;

                      i {
                        font-size: ${vars.fonts.sizeH3};
                      }
                    }
                  }

                  span:last-child {
                    color: ${vars.colors.lwBodyBlack};
                    font-family: ${vars.fonts.familySecondary};
                    font-weight: ${vars.fonts.weightNormal};
                    ${vars.breakpoints.xs} {
                      font-size: ${vars.fonts.sizeSmall};
                      line-height: 15.5px;
                    }
                    @media (min-width: 767px) {
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 18px;
                    }
                    ${vars.breakpoints.sm} {
                      font-size: ${vars.fonts.sizeDefault};
                      line-height: 18px;
                    }
                  }
                }
              }
            }
          }

          .termsAndCond {
            display: block;
            color: ${vars.colors.lwBodyBlack};
            font-family: ${vars.fonts.familySecondary};
            margin: 16px 0 18px 0;
            cursor: pointer;
            text-decoration: underline;
            &:focus-visible {
              outline: none;
            }
            ${vars.breakpoints.xs} {
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeSmall};
              line-height: 17px;
            }
            @media (min-width: 767px) {
              font-weight: ${vars.fonts.weightMedium};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 19px;
            }
            ${vars.breakpoints.sm} {
              font-weight: ${vars.fonts.weightMedium};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 19px;
            }
          }

          .btn-container {
            all: unset;
            display: flex;
            gap: 16px;

            .btn-box {
              flex: 1;
              padding 8px 24px;

              .cart-button {
                button {
                  color: ${vars.colors.lwJoinWhite};
                  background: ${vars.colors.textUnlimitedBg};
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightMediumPlus};
                  font-size: ${vars.fonts.sizeDefault};
                  border: 1px solid #009F8E;
                  line-height: 16px;
                  width: 100%;
                  text-transform: uppercase;
                  padding 8px 24px;
                  text-align: center;
                  border-radius: 4px;
                }
              }

              .login-btn {
                display: inline-block;
                color: ${vars.colors.lwBodyBlack};
                font-family: ${vars.fonts.familySecondary};
                font-weight: ${vars.fonts.weightMediumPlus};
                font-size: ${vars.fonts.sizeDefault};
                line-height: 16px;
                background: transparent;
                border: 1px solid #222222;
                width: 100%;
                text-transform: uppercase;
                cursor: pointer;
                text-align: center;
                border-radius: 4px;
                padding: 12px 24px !important;
              }
            }
          }

          .nz-btn-container {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .btn-box {
              .cart-button {
                button {
                  color: ${vars.colors.lwJoinWhite};
                  background: ${vars.colors.textUnlimitedBg};
                  font-family: ${vars.fonts.familySecondary};
                  font-weight: ${vars.fonts.weightMediumPlus};
                  font-size: ${vars.fonts.sizeDefault};
                  border: 1px solid #009F8E;
                  line-height: 16px;
                  width: 100%;
                  text-transform: uppercase;
                  padding 8px 24px;
                  text-align: center;
                  border-radius: 4px;
                }
              }

              .login-btn {
                display: inline-block;
                color: ${vars.colors.lwBodyBlack};
                font-family: ${vars.fonts.familySecondary};
                font-weight: ${vars.fonts.weightMediumPlus};
                font-size: ${vars.fonts.sizeDefault};
                line-height: 16px;
                background: transparent;
                border: 1px solid #222222;
                width: 100%;
                text-transform: uppercase;
                cursor: pointer;
                text-align: center;
                border-radius: 4px;
                padding: 12px 24px !important;
              }
            }
          }
        }
      `,
      productCardStandard: `
        ${buildThemeWidget.Product.productCardStandard}
        .product-image .product-case-default-image {
          &:not(.product-image-error) {
            min-height: unset !important;
          }
        }
      `,
    },
    Cart: {
      MiniCart: `
       z-index: 9999999999 !important;
      ${buildThemeWidget.Cart.MiniCart}
      .price-section .strike+.member-price {
        color:${vars.colors.primary6};
      }

      .member-savings {
        color: ${vars.colors.primary6};
      }
    `,
    },
    CmsTBSPage: {
      sideBarLegacy: `
        & .sidebar{
          & .grid-container{
             div{
              width:100%;
              .navigation-link{
                h4{
                  display:none; 
                }
                & a{
                  margin-left: 10px;
                  margin-bottom:12px;
                  display:flex;
                  align-items:center;
                  gap:10px;
                  font-family:${vars.fonts.familySecondary};
                  font-size: ${vars.fonts.fontSize14};
                  font-weight: ${vars.fonts.weightNormal};
                  line-height: 22.4px;
                  letter-spacing: 0.04em;
                   color: ${vars.colors.grey50};
                  &:last-child {
                    & i{
                      font-size:unset;
                    }

                  }
                }
              }
             }
          }
          & .cms-conditional-container{
            
             margin-left: -24px;
              & .grid-container > div{
                 display:flex;
                 align-items: center;
                 
                 padding-bottom:5px;
                 line-height: 25.2px;
                 letter-spacing: 0.04em;
                 gap:10px;
                  margin-bottom: 10px;
                    
                  .cms-image-container{
                   display:flex;
                   width: fit-content;
                   img{
                      width: 25px;
                   }
                 
              }
              a{
                font-family:${vars.fonts.oswald};
                 font-size: ${vars.fonts.fontSize16};
                 color: ${vars.colors.grey50};
                 margin:unset;

              }
            }
          }
        }
      `,
      breadcrumbCSS: `
        padding: 12px 24px 12px 0px;
        & a {
          display: flex;
          align-items: center;
          gap: 8px;
          &:hover, &:active, &:focus {
            text-decoration: none;
          }
          & .link-text {
            text-decoration: none;
            border-bottom: 1px solid ${vars.colors.primary8};
            font-size: ${vars.fonts.sizeH5};
            font-weight: bold;
          }
        }
      `,
    },
    Membership: {
      pageWrapper: `
      ${buildThemeWidget.Membership.pageWrapper}
      & .sidebar{
        display: none;
        ${vars.breakpoints.md} {
           display:flex;
        }
      }
      & .top-section{
          .dynamicComponents{
            >.widget-container{
             .cms-image-container{
                background: ${vars.colors.whiteWine};
                display:flex;
                justify-content:center;
                img{
                  padding:10px;
                  width:300px;
                }
              }
            }
            >.widget-cms-column{
              & .grid-container {
                    justify-content: space-between;
                    & .cms-image-container img{
                      width:88px;

                    }

              }
            }
          }
        }
        & .bottom-section{
          & .accordion-item{
             padding: 15px 0px;
            .accordion-title h3{
                font-size: ${vars.fonts.fontSize18};
            }
            .accordion-content h8{
              font-weight: normal;

            }
          }
        }
      
      `,
    },
    MembershipRebranding: {
      membershipStatus: `
      ${buildThemeWidget.MembershipRebranding.membershipStatus}
        border: 1px solid ${vars.colors.lwGrey400};
        padding: 24px;
        .re-join-note{
           margin-top: 12px;
        }
        .year-note {
          font-weight: ${vars.fonts.weightBold};
          font-family: ${vars.fonts.roboto};
          color: ${vars.colors.unltPopupBackground};
       
        }
        .add-unlimited-to-cart button{
           margin-top: 20px;
          background: ${vars.colors.unltPopupBackground};
          :hover{
           background-color: ${(vars.colors.bgColors as unknown as { [key: string]: string }).LWDarkBlue};
           color: ${vars.colors.white};
            
          }
        }
        .saving-instruction {
          font-family: ${vars.fonts.roboto};
          padding-top: 10px;
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.sizeLarge};
        }
        .saving-note {
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightNormal};
          font-size: ${vars.fonts.fontSize14};
          color: ${vars.colors.grey60};
          margin-top: 12px;
        }
        .price-section {
          display: flex;
          align-items: baseline;
        }
        .membership-status-title {
          font-family: ${vars.fonts.noto};
          font-weight: ${vars.fonts.weightMediumPlus};
        }
        .membership-subscription-title {
          font-family: ${vars.fonts.roboto};
          font-weight: 700;
          font-size: ${vars.fonts.sizeLarge};
          padding-top: 10px;
        }
        .enroll-info {
          margin-top: 12px;
          font-family: ${vars.fonts.roboto};
          font-weight: ${vars.fonts.weightBold};
          font-size: ${vars.fonts.sizeLarge};
          & .date {
            font-weight: ${vars.fonts.weightNormal};
            font-size: ${vars.fonts.sizeLarge};
            padding-right: 8px;
          }
        }
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsAPACLW, buildThemeMixinsAPACLW, buildThemeWidgetStylesAPACLW);

export default createTheme;
